body {
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    line-height: 20px;
  }
  
  
  /* Prevent zoom effect on mobile for inputs */
  input[type='text'],
  input[type='number'],
  textarea {
  font-size: 16px;
  }
  
  #app-container {
    margin: 0 auto;
    position: relative;
    height: 100vh;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    max-width: 420px;

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 100vh;

    justify-content: flex-start;
  }
  
  ::-webkit-scrollbar {
    display: none;
    }
  
  .convo-container {
    height: 100%;
    /* width: 95%; */
    max-width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow: auto;


    margin-bottom: 10px;

    /* padding-bottom: 50px; */
  }
  
  .input-container {
    width: 100%;
    background: #fff;
    max-width: 420px;
    position: fixed;
    bottom: 0;
    left: auto;
    right: auto;
    box-sizing: border-box;
    padding: 10px;
    flex-shrink: 0;
    
  }
  .input-container input {
    width: 100%;
    padding: 6px 10px;
    border-radius: 6px;
    border: 1px solid #cecece;
    box-sizing: border-box;
  }
  
  
  .chat-bubble {
    background: rgb(25, 101, 182);
    padding: 8px 15px;
    color: #fff;
    /* width: 93%; */
    box-sizing: border-box;
    
  }
  
  /* USER chat bubble */
  .user-message-container {
    display: flex;
    justify-content: flex-end;
    animation: revealup .5s 0s cubic-bezier(0,.57,.81,1.01) forwards;
  }
  
  .chat-bubble.user {
   /* background: #5f6fee; */
    background: #ff2000;
    border-radius: 20px 20px 3px 20px;
    margin: 20px 0;
  }
  
  /* BOT chat bubble */
  .chat-bubble.bot {
    box-shadow: 1px 1px 1px 2px #00000012;
    background: #0078FE;
    color: #fff;
    margin: 0;
    opacity: 1;
    border-radius: 3px 20px 20px 20px;
  
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 1px; /*cust*/
    
  }
  
  .chat-bubble.bot.suggestion {
    background: #4CAF50;
    cursor: pointer;
  }
  
  .bot-message-container {
    justify-content: flex-start; /* cust */
    display: flex;
    margin: 10px 0;
    animation: revealup .5s .2s cubic-bezier(0,.57,.81,1.01) forwards;
  }
  
    .bot-message-container > div:first-child {
        margin-right: 10px;
        width: 40px;

        justify-content: flex-start; /* cust */
    }
  
    /* .bot-message-container > div:nth-child(2) {
        flex: 0 1 auto;
    } */
  
  .bot-message-container img {
    border-radius: 50%;
    max-width: 30px;
  }
  
  
  /* General bubble styles for both USER & BOT */
  .hidden {
    opacity: 0;
  }
  
  .show {
    opacity: 1;
  }
  
  #success-overlay {
    background: #0074ae;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
    color: #fff;
    text-align: center;
    padding: 20px;
    display: none;
  }
  
  #success-overlay.active {
    display: block;
  }
  
  #pizza-timer {
    font-size: 40px;
  }
  
  /* ANIMATIONS */
  @keyframes revealup {
    from {
        opacity: 0;
        margin-top: 20px;
    }
    to { 
        opacity: 1;
        margin-top: 0;
    }
  }
  
  
  button.round {
    background-color: #ff2000;
    color: white;
    border: none;
    padding: 5px;
    font-size: 16px;
    height: 40px;
    width: 40px;
    box-shadow: 0 2px 4px darkslategray;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    top: -46px;
    left: 105%;
  }
  
  button.round:hover {
    background-color: #65b9e6;
  }
   
  button.round:active {
    box-shadow: 0 0 2px darkslategray;
    transform: translateY(2px);
  }
  

  .modal-90w .modal-dialog {
    width: 100%;
    max-width: none;
  }
  
  @media (min-width: 576px) {
    .custom-modal .modal-dialog {
      max-width: 500px;
    }
  }
  
  @media (min-width: 768px) {
    .custom-modal .modal-dialog {
      max-width: 600px;
    }
  }
  
  @media (min-width: 992px) {
    .custom-modal .modal-dialog {
      max-width: 800px;
    }
  }
  
  @media (min-width: 1200px) {
    .custom-modal .modal-dialog {
      max-width: 1000px;
    }
  }

  .typing-indicator {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
  }
  
  .typing-indicator span {
    display: inline-block;
    position: absolute;
    width: 6px;
    height: 6px;
    background: white;
    border-radius: 50%;
    animation: typing-indicator 1.4s infinite ease-in-out both;
  }
  
  .typing-indicator span:nth-child(1) {
    left: 6px;
    animation-delay: -0.32s;
  }
  
  .typing-indicator span:nth-child(2) {
    left: 18px;
    animation-delay: -0.16s;
  }
  
  .typing-indicator span:nth-child(3) {
    left: 30px;
    animation-delay: 0s;
  }
  
  @keyframes typing-indicator {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }