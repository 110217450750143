.App {
    /* font-family: sans-serif; */
    font-family: 'Product Sans', sans-serif;
    text-align: center;
    font-size: 10px;
    
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    font-family: 'Product Sans', sans-serif;
  }
  
  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    /* height: auto; */
    height: 19%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Product Sans', sans-serif;
  }
  
  .display-block {
    display: block !important;
  }
  
  .display-none {
    display: none;
  }
  

  .centered-div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: #ff4c4c;
    background-color: rgba(255,0,0,0.7);  */
    /* opacity: 0.5; */
  }


  .button-block {
    /* min-width: 10%;
    min-height: 10%; */
    
    height: 20%;
    width: 20%;
    /* top: 50%; */

    /* width: 100%; */
  }

  .mobile-modal-main {
    position: fixed;
    background: white;
    width: 94%;
    /* height: auto; */
    height: 25%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Product Sans', sans-serif;
  }
  